import { Spinner, Toaster } from "@blueprintjs/core";
import React, { useEffect, useState, Fragment } from "react";

import { apiPost } from "../../shared/api";
import useList from "../../shared/hooks/useList";
import usePopup from "../../shared/hooks/usePopup";
import List from "../../shared/components/List/List";
import Actions from "../../shared/components/Actions";
import UnArchiveConfirmationDialog from "./UnArchiveConfirmationDialog";

const toaster = Toaster.create({
  position: "top",
});

let columnDisplayOverride = {
  actions: (resource, props) => {
    return (
      <div className="inline">
        <Actions
          resourceObj={resource}
          actions={resource.actions}
          onClickMapping={props.onClickMapping(resource)}
        />
      </div>
    );
  },
};

const nonIdealStateProps = {
  header: "No Data found",
  description: "It seems you havent archived any clients",
  icon: "search",
  showNonIdealStateButton: false,
};

const formatArchiveProductListResponse = (response) => {
  const { page, total_pages, total, per_page, items, headers } = response;
  return {
    perPage: per_page,
    currentPage: page,
    totalPages: total_pages,
    total,
    items,
    headers
  };
};

const Index = () => {
  const unArchiveDialog = usePopup();
  const [resource, setResource] = useState({});
  const archivedList = useList({responseFormatter: formatArchiveProductListResponse});
  const [isUnarchiveLoading, setIsUnarchiveLoading] = useState(false);

  const onClickMapping = (resource) => {
    return {
      unarchive: () => {
        setResource(resource);
        unArchiveDialog.open();
      },
    };
  };

  columnDisplayOverride["additionalProps"] = {
    onClickMapping,
  };

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    archivedList.handlers.init("clients/archived");
  }

  const handleConfirm = async () => {
    const path = `clients/${resource.id}/unarchive`;
    setIsUnarchiveLoading(true);
    const response = await apiPost(path, {});
    if (response) {
      if(response.unarchived === true) {
        getList();
        toaster.show({
          message: "The client is unarchived successfully.",
          intent: "success",
          timeout: 3000
        })
      } else {
        toaster.show({
          message: "Unable to unarchive the client. Please contact us at support@tagalys.com for further assistance.",
          intent: "danger",
          timeout: 3000
        })
      }
      setIsUnarchiveLoading(false);
      unArchiveDialog.close();
    }
  };

  return (
    <Fragment>
      {archivedList.data.loading ? (
        <Spinner size="35" className="p-10" />
      ) : (
        <List
          list={archivedList}
          columnDisplayOverride={columnDisplayOverride}
          nonIdealStateProps={nonIdealStateProps}
          searchable={false}
        />
      )}
      <UnArchiveConfirmationDialog
        isOpen={unArchiveDialog.isOpen}
        onClose={unArchiveDialog.close}
        resetResource={() => setResource({})}
        handleConfirm={handleConfirm}
        resourceName={resource.name}
        loading={isUnarchiveLoading}
      />
    </Fragment>
  );
};

export default Index;
