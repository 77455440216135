import React, { memo, useEffect, Fragment } from "react";
import { Toaster, Spinner } from "@blueprintjs/core";
import { useImmer } from 'use-immer';

import { apiGet, apiDelete } from '../../shared/api'
import { Heading, Description, SpinnerContainer, CardItem } from "./StyledComponents";
import Actions from "../../shared/components/Actions";
import CardTable from "./CardTable";
import ThirdPartyAuthButtons from "../../shared/components/ThirdPartyAuthButtons";
import FlashCard from "../../shared/components/FlashCard";
import { OauthLogo } from "../../shared/components/ThirdPartyAuthButtons"
import ResourceDestroyAlert from "../../shared/components/ResourceDestroyAlert";

const userToast = Toaster.create({
  position: 'top'
})

const authProviderToIdMap = {
  "google_oauth2": "google",
  "linkedin": "linkedin",
  "microsoft_graph_auth": "microsoft"
}

const authProviderToLabelMap = {
  "google_oauth2": "Google",
  "linkedin": "LinkedIn",
  "microsoft_graph_auth": "Microsoft"
}

export default memo(function AuthenticationTab() {
  let [state, setState] = useImmer({
    loading: false,
    authProviders: [],
    errors: [],
    loadingAuthId: "",
    showOAuthAccountDeleteAlert: false,
    oAuthAccountToDelete: {}
  })
  const {
    loading,
    authProviders,
    errors,
    loadingAuthId,
    showOAuthAccountDeleteAlert,
    oAuthAccountToDelete
  } = state
  useEffect(() => {
    handleInit()
  }, [])

  const changeInKeyValueForActions = ({
    actionType,
    actionName,
    key,
    value
  }) => {
    setState((draft) => {
      draft.authProviders.map((authProvider) => {
        authProvider.actions[actionType].map((action) => {
          if (action.name === actionName) {
            action[key] = value
          }
        })
      })
    })
  }

  const changeInOverFlowActions = ({ actionName, key, value }) => {
    changeInKeyValueForActions({
      actionType: "overflow",
      actionName,
      key,
      value
    })
  }
  const handleInit = async () => {
    setState(draft => { draft.loading = true; })
    const response = await apiGet("omniauth/auth_providers", {}, {callback: handleInit})
    if (response) {
      setState(draft => { draft.authProviders = response.items })
    }
    setState(draft => { draft.loading = false })
  }

  const onClickMapping = (authDetails) => {
    return {
      delete: () => {
        setState(draft => {
          draft.showOAuthAccountDeleteAlert = true;
          draft.oAuthAccountToDelete = authDetails
        })
      }
    };
  };

  const destroyLinkedAccount = async () => {
    changeInOverFlowActions({ actionName: "delete", key: "loading", value: true})
    const actionObj = oAuthAccountToDelete.actions.overflow.filter(
      (action_obj) => action_obj.name === "delete"
    )[0];
    console.log(actionObj)
    const response = await apiDelete(actionObj.action.details.path, {});
    
    if (response) {
      userToast.show({
        message: `Your ${authProviderToLabelMap[oAuthAccountToDelete.provider]} account has been disconnected successfully.`,
        intent: 'success',
        timeout: 3000
      })
      handleInit()
    }
    changeInOverFlowActions({ actionName: "delete", key: "loading", value: false})
  }

  const handleLinkAccounts = async (oauthId, path) => {
    setState((draft) => {
      draft.loadingAuthId = oauthId
    })
    const response = await apiGet("user_session/can_access_secure_area", {
      secure_area: "link_oauth_account"
    })
    if (response && response.allowed_to_access) {
      window.open(path + "?link_accounts=true", "_self")
    }
    setState((draft) => {
      draft.loadingAuthId = null
    })
  }

  if (loading) {
    return (
      <SpinnerContainer>
        <Spinner size="50" />
      </SpinnerContainer>
    )
  }
  else {
    return (
      <Fragment>
        <Heading>Connected Accounts</Heading>
        <Description>
          Connect your Google/Microsoft accounts to log in to Tagalys using them.
        </Description>
        <FlashCard
          className="mb-20"
        />
        {authProviders.length > 0 &&
          <div className="flex-column">
            <h4 className="mb-20">Your Tagalys account is connected with the following account(s). You could also use them to log in.</h4>
            <CardTable
              title='Connected Accounts'
              body={
                authProviders.map((authProvider) => {
                  let selectedAuthProvider = authProviderToIdMap[authProvider.provider]
                  return (
                    <CardItem key={authProvider.id}>
                      <div className="flex-row align-center">
                        <OauthLogo provider={selectedAuthProvider} />
                        <div className="pl-5">
                          <span className="sub-content">
                            {authProvider.email}
                          </span>
                        </div>
                      </div>
                      <Actions
                        actions={authProvider.actions}
                        onClickMapping={onClickMapping(authProvider, selectedAuthProvider)}
                      />
                    </CardItem>
                  )
                })
              }
            />
          </div>
        }
        <h4 className="mt-20 mb-20">Connect your accounts</h4>
        <ThirdPartyAuthButtons
          containerStyle="flex-column third-party-container sign-in-auth-actions-container"
          buttonStyle="mb-10"
          authParams="?link_accounts=true"
          actionLabel="Connect {{provider}} account"
          onClick={handleLinkAccounts}
          loadingAuthId={loadingAuthId}
        />
        <ResourceDestroyAlert
          icon="disable"
          confirmButtonText="Disconnect"
          isOpen={showOAuthAccountDeleteAlert}
          handleCancel={() => setState((draftState) => {
            draftState.showOAuthAccountDeleteAlert = false;
            draftState.oAuthAccountToDelete = {}
          })}
          handleConfirm={() => {
            setState((draftState) => {
              draftState.showOAuthAccountDeleteAlert = false
            })
            destroyLinkedAccount()
          }}
          header="Are you sure you want to disconnect the account?"
        />
      </Fragment>
    )
  }
})