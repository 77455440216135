import { Alert } from '@blueprintjs/core'
import React from 'react'

export default function ResourceDestroyAlert({
  isOpen = false,
  icon = "trash",
  className="",
  cancelButtonText="Cancel",
  confirmButtonText = "Delete",
  handleCancel,
  handleConfirm,
  header="",
  children
}) {
  return (
    <Alert
      cancelButtonText={cancelButtonText}
      confirmButtonText={confirmButtonText}
      icon={icon}
      className={`tagalys-alert resource-delete-alert ${className}`}
      intent={"danger"}
      isOpen={isOpen}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    >
      <strong>{header}</strong>
     {children}
  </Alert>
  )
}
