import { getQueryString } from "./utils/common";
import { objectToFormData } from "object-to-formdata";
import  { Toaster } from "@blueprintjs/core"

const ApiToaster = Toaster.create({
  position: "top",
})
export const apiCall = async (path, params, abortSignal, requestType, options={}) => {
  let url = `${window.location.origin}/${path}`
  const {
    sendAsFormData = false,
    throwError = true,
    rawResponse = false,
  } = options
  try {
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    let headers = {
      "X-CSRF-Token": csrf,
      "Cache-Control": "no-store",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "X-CSRF-Token",
    };
    if (!sendAsFormData) {
      headers['Accept'] = 'application/json',
        headers['Content-Type'] = 'application/json'
    }
    if (requestType === "GET") {
      let separator = "?";
      if (url.indexOf("?") != -1) {
        separator = "&";
      }
      params['react'] = true;
      url = `${url}${separator}${getQueryString(params)}`;
    }
    let requestParams = {
      method: requestType,
      headers: headers,
      signal: abortSignal,
    };
    if (["POST", "PUT"].includes(requestType)) {
      requestParams["body"] = params;
    }
    const response = await fetch(`${url}`, requestParams);
    if (response.status === 301) {
      const redirectResponse = await response.clone().json()
      window.location.href = redirectResponse.redirect_url
      return false
    }
    if (rawResponse) {
      return response;
    }
    if (response.status === 601) {
      handleForbiddenError(response, options.callback)
      return
    }
    if (response.status === 401) {
      handleUnauthorizedResponse(response)
      return
    }
    if (response.status >= 400) {
      ApiToaster.show({
        message: "Unable to process your request. Please contact us at support@tagalys.com for further assistance.",
        intent: "danger"
      })
      return false
    }
    const data = await response.json();
    return data;
  } catch (e) {
    console.error("Exception in apiCall", e);
    if (throwError) {
      throw e;
    }
    return false;
  }
};

const handleForbiddenError = async (response, callback) => {
  const data = await response.clone().json();
  const eventToDispatch = new CustomEvent('forbidden_error', {
    detail: {
      ...data,
      callback: callback,
    }
  });
  document.dispatchEvent(eventToDispatch)
}

const handleUnauthorizedResponse = async (response) => {
  const data = await response.clone().json();
  const eventToDispatch = new CustomEvent("session-expired", {detail: data});
  document.dispatchEvent(eventToDispatch)
}

export const apiPost = async (path, params, abortSignal, options={}) => {
  const { sendAsFormData = false } = options
  if (sendAsFormData) {
    params = objectToFormData(params)
  } else {
    params = JSON.stringify(params)
  }
  return await apiCall(path, params, abortSignal, "POST", options);
};
export const apiGet = async (path, params, options) => {
  const abortController = new AbortController();
  return await apiCall(path, params, abortController.signal, "GET", options);
};
export const apiDelete = async (path, params, abortSignal, options) => {
  return await apiCall(path, params, abortSignal, "DELETE", options);
};

export const apiPut = async (path, params, abortSignal, options) => {
  params = JSON.stringify(params)
  return await apiCall(path, params, abortSignal, "PUT", options);
};