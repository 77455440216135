import React, { useState, Fragment, useEffect } from "react";
import { Alert, Icon } from "@blueprintjs/core";

export default React.memo(() => {
  let [alertOpen, setAlertOpen] = useState(false)
  let [redirectUrl, setRedirectUrl] = useState('')
  let [redirectionCause, setRedirectionCause] = useState('Current session has expired. ')
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    document.addEventListener("session-expired", handleEventListener)
  }, [])

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      handleRedirect()
    }
  }, [seconds]);

  const handleRedirect = () => {
    window.open(redirectUrl, "_self")
  }
  //need to confirm about redirection url
  const handleEventListener = (event) => {
    if(event.detail.hasOwnProperty('redirectionCause')){
      setRedirectionCause(event.detail.redirectionCause)
    }
    setAlertOpen(!alertOpen)
    setSeconds(10)
    setRedirectUrl(`${window.location.origin}/users/sign_in`)
  }
  return (
    <Fragment>
      <Alert
        className="bg-white"
        icon={<Icon intent="primary" icon={"info-sign"} iconSize={30} />}
        isOpen={alertOpen}
        confirmButtonText="Log out"
        intent="primary"
        onConfirm={() => { handleRedirect() }}
      >
        <h4 className="pb-10 m-0">{redirectionCause} You will be redirected to the login page in {seconds} seconds.</h4>
      </Alert>
    </Fragment>
  );
});
