import Styled from "styled-components";

export const Heading = Styled.h3`
  margin: 5px 0px;
  font-size: 20px;
`
export const Description = Styled.div`
  color: #696969;
  font-size: 1.1em;
  display: flex;
  line-height: 1.5em;
  margin-bottom: 20px;
`
export const InnerContainer = Styled.div`
  width: 100%;
  overflow-y:auto;
  padding:0px 5px 5px 5px;
`
export const FlexColumn = Styled.div`
  display: flex;
  flex-direction: column;
`
export const CardItem = Styled.div`
  padding: 10px 20px;
  display:flex;
  align-items:center;
  justify-content: space-between;
  border-bottom: solid 1px rgba(16, 22, 26, 0.15);
`

export const DeviceDetails = Styled.div`
  display: flex;
  flex-direction: row;
  align-items:center;
`
export const SessionHeader = Styled.h4`
  margin: 25px 0px;
`
export const StepTitle = Styled.span`
  color: #000;
  font-weight: 400;
  display: flex;
  font-size:1.2em;
  margin: 5px 0px;
  white-space: nowrap;
`
export const StepDescription = Styled.span`
  color: rgba(0, 0, 0, 0.5);
  display: block;
  padding-left:10px;
  font-size: 1.1em;
  padding-bottom: 5px;
  line-height: 1.5em;
`
export const Step = Styled.div`
  display:flex;
  flex-direction: row;
  align-items: baseline;
`
export const SpinnerContainer = Styled.div`
  width: 100%;
  height:100%;
  display:flex
  align-items:center;
  justify-content:center;
`