import React from "react";
import { Checkbox, Tooltip, Icon } from "@blueprintjs/core";

import { FlexContainer } from "../shared/styles/styled"

export default function TrustDevice({
  trustDevice,
  onChange,
  className=""
}) {
  return (
    <FlexContainer className={className}>
      <Checkbox
        id="trustDevice"
        type="checkbox"
        checked={trustDevice}
        label="Trust this device"
        onChange={onChange}
      />
      {/* 2 weeks from user session - dynamically */}
      <Tooltip content="You will be logged in for two weeks and you will not be asked for password and OTP on this device until you log out on intent.">
        <Icon size="14" className="mb-10 ml-5" icon="info-sign" />
      </Tooltip>
    </FlexContainer>
  );
}
