import React, { Fragment } from "react";
import { Position } from "@blueprintjs/core";
import { TimezonePicker } from "@blueprintjs/timezone";
const popoverProps = {
  minimal: false,
  position: Position.BOTTOM,
  popoverClassName: "single-select-popover",
  boundary: 'viewport'
};
export default React.memo(({ value, handleChange }) => {
  return (
    <div className="single-select-popover">
      <TimezonePicker
        onChange={handleChange}
        value={value}
        placeholder={value || 'UCT (UTC) +00:00'}
        valueDisplayFormat="composite"
        showLocalTimezone="true"
        popoverProps={popoverProps}
        className="timezone"
      />
    </div>
  );
});
