import { Alert } from '@blueprintjs/core'
import React from 'react'

const secondFactorMethodToLabelMap = {
  "backup_code": "Backup code",
  "otp": "OTP"
}

export default function ReSignInPrompt({
  isOpen,
  promptDetails,
  onConfirm
}) {
const { second_factor_method: secondFactorMethod  } = promptDetails
return (
  <Alert
    icon="time"
    intent="warning"
    isOpen={isOpen}
    confirmButtonText="Close"
    onConfirm={onConfirm}
  >
    <div className="bold">
      <p>
        It's been a while since you entered {secondFactorMethodToLabelMap[secondFactorMethod]}.
      </p>
      <p>
        Please, enter your credentials again to Log In.
      </p>
    </div>
  </Alert>
)}
