import React from "react";
import { useImmer } from 'use-immer';
import { Button, Collapse, InputGroup, Toaster } from "@blueprintjs/core";
import QrCode from 'qrcode.react'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { apiPost } from '../../shared/api'
import FormInput from "./FormInput";
import { StepTitle, StepDescription, Step } from "./StyledComponents";

const userToast = Toaster.create({
  position: 'top'
})

const getParams = (state) => {
  const { code } = state
  const params = { code: code }
  return params
}
export default React.memo((props) => {
  const { setBackupCodeDetails } = props
  let [state, setState] = useImmer({
    is_qr_details_generated: false,
    is_qr_details_loading: false,
    qr_code_uri: null,
    otp_secret: '************************',
    code: '',
    password: '',
    errors: {
      password: '',
      code: ''
    },
    loading: false
  })
  const { is_qr_details_generated, is_qr_details_loading, otp_secret, qr_code_uri, code, errors, loading } = state

  const generateQrDetails = async () => {
    setState(draft => { draft.is_qr_details_loading = true })
    const response = await apiPost('two_factor_authentication/setup_2fa', {});
    if (response) {
      if (response.setup) {
        setState(draft => {
          draft.loading = false;
          draft.is_qr_details_generated = true;
          draft.qr_code_uri = response.qr_code_uri;
          draft.otp_secret = response.otp_secret;
        })
      }
      else {
        userToast.show({
          message: "Unable to set up Two-factor authentication.",
          intent: 'danger',
          timeout: 3000
        })
      }
    }
    setState(draft => { draft.is_qr_details_loading = false })
  }

  const verifyTwoFactor = async () => {
    setState(draft => { draft.loading = true })
    let params = getParams(state)
    const response = await apiPost('two_factor_authentication/confirm_and_enable_2fa', params);
    if (response) {
      if (response.enable) {
        userToast.show({
          message: "Two-Factor Authentication has been enabled successfully.",
          intent: 'success',
          timeout: 3000,
          icon: 'tick'
        })
        setState(draft => {
          draft.is_qr_details_generated = false;
          draft.qr_code_uri = null;
          draft.otp_secret = '';
        })
        setBackupCodeDetails(response.backup_codes, 'enabled_two_factor')
      }
      else {
        setState(draft => { draft.errors = { ...draft.errors, ...response.error }; })
      }
    }
    setState(draft => { draft.loading = false })
  }

  const handleVerificationChange = (evt) => {
    const { id, value } = evt.target
    setState(draft => { draft[id] = value, draft.errors = { ...draft.errors, [id]: '' } })
  }
  return (
    <div>
      <h4 className="pb-10">Follow the steps below to setup Two-Factor Authentication:</h4>
      <Step>
        <StepTitle>Step 1: </StepTitle>
        <StepDescription>
          Click the "<strong>Set-up Two-Factor</strong>" button below.
        </StepDescription>
      </Step>
      <Step>
        <StepTitle>Step 2: </StepTitle>
        <StepDescription>
          Use any athenticator application like Google Authenticator or Authy to scan the QR code or manually enter the setup key/code.
        </StepDescription>
      </Step>
      <div className="flex-column pt-10 align-center">
        <div className={!is_qr_details_generated ? "qr-container" : "m-30"}>
          <div className={!is_qr_details_generated ? "qr-image" : ""}>
            <QrCode size={200} value={!is_qr_details_generated ? 'https://www.tagalys.com/' : qr_code_uri} ></QrCode>
          </div>
          <div className={!is_qr_details_generated ? "qr-button" : ""}>
            {!is_qr_details_generated &&
              <Button
                intent="primary"
                loading={is_qr_details_loading}
                onClick={() => { generateQrDetails() }}>
                Set-up Two-Factor
              </Button>
            }
          </div>
        </div>
        <InputGroup
          value={otp_secret}
          readOnly={true}
          disabled={!is_qr_details_generated}
          rightElement={
            <CopyToClipboard text={otp_secret}
              onCopy={() => {
                userToast.show({
                  icon: "duplicate",
                  message: "QR code has been copied to your clipboard.",
                  intent: 'none',
                  timeout: 3000
                })
              }}>
              <Button disabled={!is_qr_details_generated} icon='duplicate' minimal='true' />
            </CopyToClipboard>}
        />
      </div>
      <Collapse isOpen={is_qr_details_generated}>
        <div className="mt-20">
          <FormInput
            id="code"
            label=" OTP:"
            helperText={errors.code !== '' ? errors.code : ''}
            intent={errors.code !== '' ? 'danger' : 'none'}
            value={code}
            handleChange={handleVerificationChange}
          />
          <div className="flex-row">
            <Button
              intent="success"
              loading={loading}
              onClick={verifyTwoFactor}
            >
              Verify and Enable
            </Button>
          </div>
        </div>
      </Collapse>
    </div>
  );
});