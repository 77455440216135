import React, { memo, Fragment, useEffect } from "react";
import { FormGroup, Button, Toaster, Spinner, Icon, Tooltip } from "@blueprintjs/core";
import { useImmer } from 'use-immer';
import { apiGet, apiPost } from '../../shared/api'
import FormInput from "./FormInput";
import Timezone from "../../shared/components/Timezone";
import { Heading, Description, FlexColumn, SpinnerContainer } from "./StyledComponents";
import * as Styled from "../../shared/styles/styled"

const userToast = Toaster.create({
  position: 'top'
})

const HelperText = ({ hasError, error }) => {
  if (hasError) {
    return <ul className="pl-20">
      { error.first_name.map((error) => <li>{error}</li>) }
    </ul>
  }
  return null
}

const getParams = (state) => {
  const { first_name, last_name, time_zone, id } = state
  const params = {
    first_name: first_name,
    last_name: last_name,
    time_zone: time_zone,
    id: id
  }
  return params
}

export default memo(function ProfilePanel() {
  let [profileDetails, setProfileDetails] = useImmer({
    user_details: {
      id: null,
      first_name: '',
      last_name: '',
      time_zone: '',
      email: '',
    },
    error: {
      first_name: []
    },
    loading: {
      form: false,
      page: false,
    }
  })
  const { error, loading, user_details } = profileDetails
  const { email, first_name, last_name, time_zone } = user_details

  useEffect(() => {
    handleInit()
  }, [])

  const handleInit = async () => {
    setProfileDetails(draft => { draft.loading.page = true });
    const response = await apiGet("user/get_user_profile", {})
    if (response) {
      setProfileDetails(draft => { draft.user_details = { ...response } })
    }
    setProfileDetails(draft => { draft.loading.page = false });
  }

  const handleSave = async () => {
    setProfileDetails(draft => { draft.loading.form = true })
    const params = getParams(user_details)
    const response = await apiPost("user/update_profile", params);
    if (response) {
      if (response.saved) {
        userToast.show(response.toast)
      }
      else {
        setProfileDetails(draft => {
          draft.error = {
            ...draft.error,
            ...response.error
          }
        })
      }
    }
    setProfileDetails(draft => { draft.loading.form = false })
  }

  const handleInputChange = (evt) => {
    const { id, value } = evt.target
    setProfileDetails(draft => { draft.user_details[id] = value })
    if (error[id] && error[id].length > 0) {
      setProfileDetails(draft => { draft.error[id] = [] })
    }
  }
  const hasErrorInFirstName = error.first_name.length
  // HOC with spinner
  if (loading.page) {
    return (
      <SpinnerContainer>
        <Spinner size="50" />
      </SpinnerContainer>
    )
  }
  else {
    return (
      <Fragment>
          {/* REV Header and description */}
          <Heading>User Profile</Heading>
          <Description>Update your username and manage your account.</Description>
          <FormInput
            id="email" 
            label="Email"
            value={email} 
            disabled={true}
          />
          <FormInput
            id="first_name"
            label="First Name"
            helperText={<HelperText 
              hasError={hasErrorInFirstName}
              error={error}
            />}
            intent={hasErrorInFirstName ? "danger" : "none"}
            value={first_name}
            handleChange={handleInputChange}
          />
          <FormInput
            id="last_name"
            label="Last Name"
            value={last_name}
            handleChange={handleInputChange}
          />
          <div className="form-input">
            <FormGroup
            label={
              <Styled.FlexContainer id="time-zone-label">
                <p>
                  User Time Zone
                </p>
                <Tooltip position="top" content={"You will receive Tagalys performance emails and alerts based on the time zone set here"}>
                  <Icon className="ml-5" icon="info-sign"/>
                </Tooltip>
              </Styled.FlexContainer>
            }
              className="mb-15"
            >
              <Timezone
                value={time_zone}
                handleChange={(timeZone) => {
                  setProfileDetails(draft => {
                    draft.user_details.time_zone = timeZone
                  })
                }}
              />
            </FormGroup>
          </div>
        <Button
          intent="success"
          loading={loading.form}
          onClick={handleSave}
        >
          Save
        </Button>
      </Fragment>
    )
  }
})