import React from "react";
import { FormGroup, InputGroup } from "@blueprintjs/core";

export default React.memo(({
  label,
  id,
  value,
  handleChange = null,
  placeholder = '',
  rightElement = null,
  inputFieldClassName = 'input-field-style',
  helperText = null,
  intent = 'none',
  disabled = false,
  type = "text",
}) => {
  return (
    <div className="form-input">
      <FormGroup
        label={label}
        intent={intent}
        helperText={helperText}
      >
        <InputGroup
          id={id}
          value={value || ''}
          type={type}
          intent={intent}
          rightElement={rightElement}
          placeholder={placeholder}
          disabled={disabled}
          className={inputFieldClassName}
          onChange={handleChange}
        />
      </FormGroup>
    </div>
  );
});
