import { Toaster as BpToaster } from '@blueprintjs/core'
import React, { Fragment }from 'react'
import { useHistory } from 'react-router-dom'
import SignIn from './SignIn'

const Toaster = BpToaster.create({
  position: "top"
})

export default function AuthenticateUser(props) {
  const history = useHistory()
  const getParams = (formDetails) => {
    return {
      user: {
        email: formDetails.email,
        password: formDetails.password,
        trust_device: formDetails.trustDevice
      }
    }
  }

  const getOtpParams = ({value, secondFactorMethod, trust_device}) => {
    return {
      user: {
        otp_attempt: value,
        second_factor_method: secondFactorMethod,
        trust_device
      }
    }
  }
  
  const config = {
    showTrustDevice: true,
    firstFactorPath: "users/sign_in",
    twoFactorPath: "session/authenticate_with_two_factor",
    getParams: getParams,
    getOtpParams: getOtpParams
  }
  const handleFirstFactorResponse = (response) => {
    if (response.hasOwnProperty("redirect_path")) {
      if (response.react) {
        history.push(response.redirect_path)
        return
      }
      window.location.href = `${window.location.origin}${response.redirect_path}`
      return
    }
  }

  const handleTwoFactorResponse = (response) => {
    if (response.toast) {
      Toaster.show(response.toast)
    }
    if (response.redirect_path && response.react) {
      history.push(response.redirect_path)
      return
    }
    if (response.signed_in && response.hasOwnProperty('redirect_path')) {
      window.location.href = `${window.location.origin}${response.redirect_path}`
      return
    }
  }

  return (
    <SignIn
      {...props}
      handleFirstFactorResponse={handleFirstFactorResponse}
      handleTwoFactorResponse={handleTwoFactorResponse}
      config={config}
    />
  )
}
