import React from "react";
import { Button } from "@blueprintjs/core";
import useAppData from "../hooks/useAppData";

export const OauthLogo = ({ provider }) => {
  const imageAssets = useAppData("assets")
  return <img className="mr-5" src={imageAssets[provider]} height="18" width="18" />
}

const authApps = [{
  id: "google",
  label: "Google",
  className: "google-logo",
  authUrl: 'google.com'
}, {
  id: "microsoft",
  label: "Microsoft",
  className: "microsoft-logo",
  authUrl: 'microsoft.com'
}]

export default React.memo(({
  containerStyle,
  actionLabel = "Log in with {{provider}}",
  buttonStyle,
  authParams = "",
  onClick = (id, path) => {
    window.open(path + authParams, "_self")
  },
  // loading for which auth
  loadingAuthId = ""
}) => {
  const oauthPaths = useAppData("oauth_paths")
  return (
    <section className={containerStyle}>
      {
        authApps.map(({ id, label, icon }) => {
          return (
            <Button
              loading={loadingAuthId === id}
              key={id}
              large
              minimal
              outlined="true"
              className={buttonStyle}
              onClick={() => onClick(id, oauthPaths[id])}
            >
              <div className="center">
                <OauthLogo provider={id}/>
                <span>{actionLabel.replace("{{provider}}", label)}</span>
              </div>
            </Button>
          )
        })}
    </section>
  );
});
