import React, { useState } from 'react'
import TwoFactorScreen from './TwoFactorScreen'
import ReSignInPrompt from './ReSignInPrompt'
import TrustDevice from './TrustDevice'
import { useImmer } from 'use-immer'
import useAppData from '../shared/hooks/useAppData'

export default function TwoFactorScreenForAuthProvider() {

  const [reSignInPromptDetails, setReSignInPromptDetails] = useImmer({
    showPrompt: false,
    details: {
      redirect_path: "",
      second_factor_method: ""
    }
  })
  const { re_authentication_flow } = useAppData("authorization")
  const getOtpParams = ({value, secondFactorMethod, trust_device}) => {
    return {
      user: {
        otp_attempt: value,
        second_factor_method: secondFactorMethod,
        trust_device
      }
    }
  }

  const onConfirm = () => {
    window.location.href = `${window.location.origin}${reSignInPromptDetails.details.redirect_path}`
  }

  const openReSignInPrompt = () => {
    setReSignInPromptDetails((draft) => {
      draft.showPrompt = true
    })
  }

  const setPromptDetails = (details) => {
    setReSignInPromptDetails((draft) => {
      draft.details = details
    })
  }

  const handleTwoFactorResponse = (response) => {
    if (response.valid_otp_attempt === false) {
      setPromptDetails(response)
      openReSignInPrompt()
      return
    }
    if (response.authorized) {
      window.close()
      return 
    }
    if (response.signed_in && response.hasOwnProperty('redirect_path')) {
      window.location.href = `${window.location.origin}${response.redirect_path}`
    }
  }
  return (
    <div>
      <TwoFactorScreen
        twoFactorPath={"session/authenticate_with_two_factor"}
        handleTwoFactorResponse={handleTwoFactorResponse}
        getOtpParams={getOtpParams}
        showTrustDeviceCheckbox={!re_authentication_flow}
      />
      <ReSignInPrompt
        isOpen={reSignInPromptDetails.showPrompt}
        promptDetails={reSignInPromptDetails.details}
        onConfirm={onConfirm}
      />
    </div>
  )
}
