import React from 'react'
import styled from 'styled-components'
import useAppData from '../shared/hooks/useAppData'

const Container = styled.div`
  margin: 10px 0px 25px 0px;
`

export default function TagalysBanner({ imgSrc = false }) {
  const tagalysImgSrc = imgSrc ? imgSrc : useAppData("assets")["tagalys"]
  return (
    <Container className="center">
      <img
        width="120px"
        height="auto"
        src={tagalysImgSrc}
      />
    </Container>
  )
}
