import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Tabs, Tab } from "@blueprintjs/core";
import { FlexColumn } from "./StyledComponents";

const routes = [{
  label: 'User Profile',
  path: "/profile"
},
{
  label: 'Sessions',
  path: "/security/sessions"
},
{
  label: 'Change Password',
  path: "/security/change_password"
},
{
  label: 'Connected Accounts',
  path: "/security/authentication"
},
{
  label: ' Two-Factor Authentication',
  path: "/security/two_factor_authentication"
},
]
export default React.memo(() => {
  let [selectedTabId, setSelectedTabId] = useState('')
  let history = useHistory();
  const { path  } = useRouteMatch()
  const handleTabChange = (routePath) => {
    history.push(routePath)
    setSelectedTabId(routePath)
  }
  useEffect(() => {
    setSelectedTabId(window.location.pathname)
  }, [])
  return (
    <FlexColumn>
      <Tabs
        id="settings"
        onChange={handleTabChange}
        vertical='true'
        className='vertical-nav'
        selectedTabId={selectedTabId}
        large
        animate={false}
      >
        {
          routes.map((route) => {
            return (<Tab
              id={`${path}${route.path}`}
              key={route.path}
              title={route.label}
            />)
          })
        }
      </Tabs>

    </FlexColumn>
  );
});
