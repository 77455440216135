import { Callout } from '@blueprintjs/core'
import React from 'react'

export default function Flash({ messages = [], intent, className = "", message = "" }) {
  if (typeof message === "string" && message.length) {
    return (
      <Callout intent={intent} className={`flash ${className}`}>
        {message}
      </Callout>
    )
  }
  if (Array.isArray(messages)) {
    if (messages.length) {
      const components= messages.map((message, index) => {
        return <p
          key={index}
          dangerouslySetInnerHTML={{ __html: message }}>
        </p>
      })
      return (
        <Callout intent={intent} className={`flash ${className}`}>
          {components}
        </Callout>
      )
    }
  }
  return null
}
