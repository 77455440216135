import { useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { AnchorButton, Button, NumericInput, Tooltip } from "@blueprintjs/core";

import debounce from '../utils/debounce';
import { getParamsAsObject, getQueryString, testAttributeGenerator, pageCalculator } from "../utils/common";

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  showCurrentPageOnly,
  small = false,
  canSetParamsToUrl,
  perPage,
  maxAllowedPage,
  loading = false
}) => {

  const history = useHistory();
  const maxAllowedPageReached = currentPage >= maxAllowedPage
  const disabled = (currentPage >= totalPages || maxAllowedPageReached || loading)
  const [localPageNumber, setLocalPageNumber] = useState(currentPage)

  useEffect(() => {
    if(currentPage !== null){
      if(canSetParamsToUrl){
        setParamsToUrl(currentPage)
      }
      setLocalPageNumber(currentPage)
    }
  }, [currentPage])

  const setParamsToUrl = (currentPage) => {
    const params = getParamsAsObject(history.location.search);
    params.page = currentPage;
    if(perPage){
      params.per_page = perPage;
    }
    history.replace(`${history.location.pathname}?${getQueryString(params)}`);
  }

  const handlePageChange = (pageNumber) => {
    if(isValidPageNumber(pageNumber)){
      onPageChange(parseInt(pageNumber))
    }
  }

  const handlePageChangeWithDebounce = useCallback(debounce(handlePageChange, 500), [onPageChange, totalPages])

  const handlePageInputChange = (numberAsString) => {
    setLocalPageNumber(numberAsString)
    handlePageChangeWithDebounce(numberAsString)
  }

  const renderPages = () => {
    let pagesToShow = pageCalculator(currentPage, totalPages);
    if (!pagesToShow.includes(currentPage)) {
      pagesToShow.push(currentPage);
    }

    return pagesToShow.map((pageNumber, index) => {
      return (
        <Button
          {...testAttributeGenerator('page', pageNumber)}
          intent={currentPage === pageNumber ? "primary" : ""}
          className="page-link"
          key={index}
          small={small}
          onClick={() =>
            handlePageChange(pageNumber)
          }
        >
          {pageNumber}
        </Button>
      );
    });
  };

  const isValidPageNumber = (pageNumber) => {
    const page = parseFloat(pageNumber)
    if(!isNaN(pageNumber) && typeof page === "number" && page <= totalPages && page > 0 && Number.isInteger(page)){
      return true
    }
    return false
  }

  return (
    <div className="pagination-container">
      {totalPages > 1 ? (
        <React.Fragment>
          <Button
            {...testAttributeGenerator('page', 'previous_page')}
            minimal
            disabled={(currentPage === 1 || loading)}
            icon="caret-left"
            small={small}
            className="page-link"
            onClick={() =>
              currentPage !== 1 ? handlePageChange(currentPage - 1) : null
            }
          ></Button>
          {showCurrentPageOnly ? (
            <span className="page-info align-center">
              <span {...testAttributeGenerator('page', 'current_page_number')}>
                <NumericInput
                  min={1}
                  max={totalPages}
                  onValueChange={handlePageInputChange}
                  value={localPageNumber}
                  buttonPosition="none"
                  intent={isValidPageNumber(localPageNumber) ? "none" : "danger"}
                  className={"current-page-number mr-5"}
                  disabled={loading}
                  {...testAttributeGenerator('page', 'current-page-input')}
                />
              </span>
              <span>
                of&nbsp;
              </span>
              <span>
                {totalPages}
              </span>
            </span>
          ) : (
            renderPages()
          )}
          <Tooltip
            content={"You have reached the maximum limit and cannot move to next page"}
            position="top"
            disabled={!maxAllowedPageReached}
          >
            <AnchorButton
              {...testAttributeGenerator('page', 'next_page')}
              minimal
              className="page-link"
              small={small}
              icon="caret-right"
              disabled={disabled}
              onClick={() =>
                currentPage !== totalPages ? handlePageChange(currentPage + 1) : null
              }
            />
          </Tooltip>
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default React.memo(Pagination);