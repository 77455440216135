import React, { Fragment, memo, useState } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Dialog, Callout, Button, Toaster } from "@blueprintjs/core";

const userToast = Toaster.create({
  position: 'top'
})
export default memo(function BackupCodesDialog(props) {
  let [isCodesCopied, setIsCodesCopied] = useState(false)
  const BackupCodes = () => {
    let element = []
    props.backupCodesDetails.backUpCodes.map((code) => {
      element.push(<div key={code} id={code} className="backup-code center">{code}</div>)
    })
    return element
  }

  return (
    <Fragment>
      <Dialog
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        usePortal={true}
        title="Backup Codes"
        isOpen={props.backupCodesDetails.showBackupCodes}
        onClose={() => { props.resetBackupDetails() }}
        enforceFocus={false}
        className="bg-white"
      >
        <div>
          <div className="m-20">
            <Callout icon='info-sign' intent="warning" className="tab-header">
              If you lose access to your authentication device, you can use one of these backup codes to log in to your account. Each code may be used only once. These codes can be seen once so kindly make a copy, and store it in a secured place.
            </Callout>
          </div>
          <div className="center">
            <CopyToClipboard text={props.backupCodesDetails.backUpCodes.join('\n')}
              onCopy={() => {
                setIsCodesCopied(true);
                userToast.show({
                  message: "Backup codes have been copied to your clipboard.",
                  intent: 'none',
                  timeout: 3000,
                  icon: 'duplicate'
                })
              }}>
              <Button
                icon='duplicate'
                intent={isCodesCopied ? 'success' : 'none'}
                outlined='true'
              >
                Copy Backup Codes
              </Button>
            </CopyToClipboard>
          </div>
          <div className="code-container">
            {
              BackupCodes()
            }
          </div>
        </div>
      </Dialog>
    </Fragment >
  )
})